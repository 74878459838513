@charset "UTF-8";
/*--------------------------------------------------------------------
  Reset
--------------------------------------------------------------------*/
*,
*::after,
*::before {
	box-sizing: inherit;
}

html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, img, small, strong, b, dl, dt, dd, ol, ul, li, form, label, table, caption, tbody, tfoot, thead, tr, th, td, article, figure, figcaption, footer, header, nav, section {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, figcaption, figure, footer, header, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}


button, select, input {
  background: none;
  border: none;
  border-radius: 0;
  appearance: none;
  outline: none;
  padding: 0;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="date"]::-webkit-inner-spin-button{
  -webkit-appearance: none;
}
input[type="date"]::-webkit-clear-button{
  -webkit-appearance: none;
}

// For IE
select::-ms-expand {
  display: none;
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
}
