.c-lead {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: .6rem;
  border-bottom: 3px solid $c-blue;
  @include sc('tablet') {
    font-size: 1.8rem;
  }

  &.-with-back,
  &.-with-edit {
    display: flex;
    align-items: center;
  }

  &.-required {
    span {
      @include requiredMark();
    }
  }

  &_back {
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    display: inline-block;
    margin-right: 2rem;
    @include sc('tablet') {
      width: 3rem;
      height: 3rem;
    }
  }

  &_edit-btn {
    margin-left: auto;
  }

  &_sub-txt {
    display: inline-block;
    margin-left: 4rem;
  }
}