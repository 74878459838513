.c-btn {
  font-weight: bold;
  border-radius: 999px;
  line-height: 1;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include hover();

  &.-primary {
    font-size: 1.8rem;
    text-align: center;
    min-width: 28rem;
    height: 6rem;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem 2rem;
    color: $c-white;
    background-color: $c-blue;
    box-shadow: $box-shadow;
    @include sc('tablet') {

    }

    &.-green {
      background-color: $c-green;
    }
  }

  &.-secondary {
    font-size: 1.6rem;
    text-align: center;
    min-width: 18rem;
    min-height: 4rem;
    padding: 1.2rem 2.6rem;
    color: $c-blue;
    border: 1px solid $c-blue;
    background-color: $c-white;
  }

  &.-tertiary {
    font-size: 2rem;
    color: $c-white;
    text-align: center;
    line-height: 1.2;
    width: 28rem;
    height: 10rem;
    border-radius: 10px;
    box-shadow: $box-shadow;
    @include sc('tablet') {
      font-size: 1.4rem;
      width: 12rem;
      height: 8rem;
      line-height: 1.4;
      padding: 1rem .5rem;
    }

    &.-red {
      background-color: $c-red;
    }
    &.-yellow {
      background-color: $c-yellow;
    }
    &.-green {
      background-color: $c-green-light;
    }
  }

  &.-small {
    font-size: 1.6rem;
    text-align: center;
    min-width: 10rem;
    height: 4rem;
    padding: 1rem 3rem;
    color: $c-white;
    background-color: $c-blue;
    @include sc('tablet') {
      min-width: 10rem;
    }
  }

  // ICONS
  &.-icon {
    min-width: unset;
    padding: .5rem 2rem;
    &:before {
      content: "";
      margin-right: 1rem;
      display: inline-block;
      flex-shrink: 0;
    }

    &.-logout {
      &:before {
        content: none;
        width: 20px;
        height: 20px;
        margin-top: 4px;
        @include svg-logout();
      }
      svg {
        width: 20px;
        height: 20px;
        margin-top: 4px;
        margin-right: 1rem;
      }
    }
    &.-ical {
      &:before {
        width: 23px;
        height: 23px;
        background: url(../img/icons/iCal.svg) no-repeat;
      }
    }
    &.-google {
      &:before {
        width: 23px;
        height: 23px;
        background: url(../img/icons/google_icon.svg) no-repeat;
      }
    }
  }

}