/* ==============================================================================
  FOUNDATIONS      Bases, Varriables and Mixins etc..
============================================================================== */

@import "../_partials/_foundations/variables";
@import "../_partials/_foundations/mixins";
@import "../_partials/_foundations/reset";
@import "../_partials/_foundations/bases";


/* ==============================================================================
  COMPONENTS    Class name starting with 'c-'
============================================================================== */

@import "../_partials/_components/svg";
@import "../_partials/_components/button";
@import "../_partials/_components/lead";
@import "../_partials/_components/form_parts";
@import "../_partials/_components/text";
@import "../_partials/_components/schedule";
@import "../_partials/_components/calendar";
@import "../_partials/_components/notice";
@import "../_partials/_components/interview_table";
@import "../_partials/_components/invited";
@import "../_partials/_components/full-button";
@import "../_partials/_components/modal";


/* ==============================================================================
  LAYOUT      Class name starting with 'l-'
============================================================================== */

@import "../_partials/_layouts/header";
@import "../_partials/_layouts/footer";
@import "../_partials/_layouts/side_menu";
@import "../_partials/_layouts/block";


/* ==============================================================================
  PROJECTS    Class name starting with 'p-'
============================================================================== */

@import "../_partials/_projects/login";
@import "../_partials/_projects/details";



/* ==============================================================================
  UTILITIES    Class name starting with 'u-'
============================================================================== */

@import "../_partials/utilities";