/* ---------------------------------------------------------
  COLORS
--------------------------------------------------------- */

$c-white: #fff;
$c-black: #333;

$c-blue: #2E86D7;
$c-blue-light: #F4F9FD;
$c-green: #00B900;
$c-green-light: #8EC31F;
$c-orange: #EA8825;
$c-yellow: #FDAE13;
$c-red: #D90D32;

$c-gray: #666; //リンクテキストなど
$c-gray2: #999999;

$c-gray-light: #707070;
$c-gray-light2: #999;
$c-gray-light3: #ccc; // ボーダーで多用
$c-gray-light4: #F8F8F8; // 背景など
$c-gray-light5: #ddd; //ボーダーなど


/* ---------------------------------------------------------
  FONT FAMILY
--------------------------------------------------------- */

$f-base: "游ゴシック Medium", "Yu Gothic Medium", sans-serif;
$f-lato: 'Lato', sans-serif;


/* ---------------------------------------------------------
  SIZE
--------------------------------------------------------- */

$s-header-h-pc: 6rem;
$s-header-h-sp: 5rem;

$s-side-w: 30rem;
$s-max-contents-w: 120rem;


/* ---------------------------------------------------------
  OTHERS
--------------------------------------------------------- */
$box-shadow: 0 4px 15px -11px #000;
$transition: .2s;