.c-form {

  &_inner {
    display: flex;
    align-items: center;
    @include sc('tablet') {
      display: block;
    }
  }

  &_label {
    width: 21rem;
    font-weight: 700;
    display: inline-block;
    @include sc('tablet') {
      width: 100%;
      margin-bottom: 1rem;
      line-height: 1;
    }
  }

  // テキスト、プルダウン共通
  &_input,
  &_pd {
    width: 100%;
    height: 4rem;
    border-radius: 4px;
    border: 1px solid $c-gray-light2;
    &.-in-col {
      width: calc( 100% - 21rem);
    }
    &.-short {
      width: 50rem;
    }
    &.-mini {
      width: 9rem;
    }
    @include sc('tablet') {
      width: 100%;
      &.-in-col,
      &.-short {
        width:100%;
      }
    }
  }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    border-color: $c-blue ;
  }

  // テキストインプット
  &_input {
    font-size: inherit;
    line-height: 1;
    padding: 0 1.5rem;
  }

  // プルダウン
  &_pd {
    position: relative;
    select {
      width: 100%;
      height: 100%;
      padding: 0 3rem 0 1.5rem;
      font-size: inherit;
      line-height: 1;
      display: block;
      cursor: pointer;
    }
    .-triangle {
      width: 12px;
      height: 10px;
      display: block;
      background-image: url(../img/icons/triangle.svg);
      position: absolute;
      right: 1rem;
      top: 1.5rem;
    }
  }

  // checkbox
  &_cb {
    &.-center label {
      justify-content: center;
    }

    input[type="checkbox"] {
      display: none !important;
    }

    label {
      font-weight: 700;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &_box {
      width: 20px;
      height: 20px;
      margin-right: 2rem;
      display: inline-block;
      position: relative;
      border: 1px solid $c-gray-light3;
      cursor: pointer;
      @include sc('tablet') {
        width: 30px;
        height: 30px;
      }
    }

    input[type="checkbox"]:checked + label &_box {
      background-color: $c-blue;
      border-color: $c-blue;
      &:after {
        content: "";
        position: absolute;
        top: 8%;
        bottom: 0;
        left: 33%;
        display: block;
        width: 7px;
        height: 12px;
        border-right: 2px solid $c-white;
        border-bottom: 2px solid $c-white;
        transform: rotate(40deg);
        @include sc('tablet') {
          width: 11px;
          height: 18px;
        }
      }
    }
  }

  // ラジオボタン
  &_radio {
    &.-center label {
      justify-content: center;
    }

    input[type="checkbox"] {
      display: none !important;
    }

    label {
      font-weight: 700;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &_box {
      width: 20px;
      height: 20px;
      margin-right: 2rem;
      display: inline-block;
      position: relative;
      border: 1px solid $c-gray-light3;
      border-radius: 9999px;
      cursor: pointer;
      @include sc('tablet') {
        width: 30px;
        height: 30px;
      }
    }

    input[type="radio"]:checked + label &_box {
      border-color: $c-blue;
      &:after {
        content: "";
        width: 80%;
        height: 80%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 9999px;
        background-color: $c-blue;
      }
    }
  }

  // 項目・エリア選択
  &_area-check {
    width: 20rem;
    height: 4rem;
    @include sc('tablet') {
      width: calc( 50% - 2rem );
    }
    input[type="checkbox"] {
      display: none;
    }
    label {
      width: 100%;
      height: 100%;
      font-size: 1.8rem;
      font-weight: 700;
      padding: 1rem;
      border-radius: 4px;
      border: 1px solid $c-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {
      color: $c-white;
      background-color: $c-blue;
      border-color: $c-blue;
    }

  }

  // トグル
  &_toggle {
    label {
      width: 4rem;
      height: 2rem;
      display: flex;
      border: 1px solid $c-gray-light3;
      border-radius: 999px;
      position: relative;
      cursor: pointer;
      &:before {
        content: "";
        width: 14px;
        height: 14px;
        border-radius: 999px;
        background-color: $c-white;
        border: 1px solid $c-gray-light3;
        position: absolute;
        top: 2px;
        left: 2px;
        transition: left .2s, right .2s;
      }
      @include sc('tablet') {
        width: 6rem;
        height: 3rem;
        &:before {
          width: 21px;
          height: 21px;
          top: 3px;
        }
      }
    }

    input[type=checkbox] + label {
    } // input

    input[type=checkbox]:checked + label {
      background-color: $c-blue;
      border-color: $c-blue;
      &:before {
        border-color: $c-blue;
        left: 23px;
        @include sc('tablet') {
          left: 33px;
        }
      }
    } // checked
  }

  // テキストエリア
  &_ta {
    width: 100%;
    font-size: inherit;
    padding: 1rem;
    border: 1px solid $c-gray-light2;
    border-radius: 4px;
    outline: none;
    resize: none;
    &.-short {
      width: 50rem;
    }
    @include sc('tablet') {
      &.-short {
        width: 100%;
      }
    }
  }

  // カレンダー
  &_date {
    display: flex;
    align-items: center;
    @include sc('tablet') {
      display: block;
    }

    &_ttl {
      font-size: 2rem;
      font-weight: 700;
      width: 19rem;
      padding-left: 3rem;
      @include sc('tablet') {
        font-size: 1.8rem;
        padding-left: 0;
      }
    }

    &_col {
      display: flex;
      align-items: center;
      font-weight: 700;
      > * {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
      @include sc('tablet') {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
      }

      &_child {
        display: flex;
        align-items: center;
        > * {
          &:not(:last-child) {
            margin-right: 2rem;
            @include sc('tablet') {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    .-label {
      display: block;
      width: 16rem;
      height: 4rem;
      border: 1px solid $c-gray-light2;
      border-radius: 4px;
      position: relative;

      .-input {
        font-size: 1.6rem;
        font-weight: 700;
        width: 100%;
        height: 100%;
        padding: 1rem;
        &::-webkit-calendar-picker-indicator{
          width: 4rem;
          height: 4rem;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          padding: 0;
          background: transparent;
          color: transparent;
          cursor: pointer;
        }
      }

      .-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 8px;
        right: 10px;
        pointer-events: none;
      }
    }
  }


  // エラー時
  &.-has-err {
    .c-form {
      &_input,
      &_pd,
      &_ta {
        border-color: $c-red;
      }
      &_err-msg {
        display: block !important;
      }
    }
  }

  &.-required {
    .-lbl {
      @include requiredMark();
    }
  }

  &_err-msg {
    color: $c-red;
    display: none !important;
    font-weight: 400;
    &.-in-col {
      padding-left: 21rem;
    }
  }

}