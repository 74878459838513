
html {
  font-size: 62.5%; // 1rem = 10px
  min-height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  color: $c-black;
  font-size: 1.6rem;
  font-family: $f-base;
  font-weight: 500;
  line-height: 1.75;
  background-color: $c-white;
  box-sizing: border-box;
  @include sc('tablet') {
    margin-top: $s-header-h-sp;
  }

  &.-isModalOpen {
    height: 100vh;
    overflow-y: hidden;
    padding-right: 15px;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

main {
  display: block; // For IE11
}

a {
  cursor: pointer;
  @include hover();
}

input[type="text"],
input[type="mail"],
input[type="password"],
textarea {
  &:focus {
    border: 1px solid $c-blue;
  }
}

.-disabled {
  opacity: 0.2;
  pointer-events: none;
}

svg {
  width: 100%;
  path,
  polygon,
  circle,
  rect {
    &.-color {
      fill: $c-blue;
    }
    &.-white {
      fill: $c-white;
    }
  }
}


// IEのみ游ゴシックでレイアウトが変わるため個別対応
@media all and (-ms-high-contrast: none) {
  body {
    font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif;
  }
}