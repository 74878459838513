/* ======================================================================
MEDIA QUERY MANAGER
========================================================================= */

@mixin sc($breakpoint) {
  @if $breakpoint == "sp" {
      @media (max-width: '480px') { @content };
      // @media (max-width: '768px') { @content };
  }
  @if $breakpoint == "tablet" {
    @media (max-width: '768px') { @content };
}
}

/* ======================================================================
 DESIGN
========================================================================= */

@mixin requiredMark() {
  &:after {
    content: "＊";
    color: $c-red;
  }
}

/* ======================================================================
 HOVER
========================================================================= */

@mixin hover() {
  &:hover {
    opacity: 0.7;
  }
}


/* ======================================================================
 LAYOUT
========================================================================= */

@mixin centering() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// To Make Liquid Container
@mixin ratioIs($w, $h) {
  &:before{
    content: "";
    display: block;
    padding-bottom: calc( #{$h} / #{$w} * 100% );
  }
}

/* ======================================================================
 DESIGN PATTERN
========================================================================= */

@mixin statusColors() {
  &.-yellow {
    color: $c-yellow;
    border-color: $c-yellow;
  }
  &.-red {
    color: $c-red;
    border-color: $c-red;
  }
  &.-green {
    color: $c-green-light;
    border-color: $c-green-light;
  }
}

@mixin statusBgColors() {
  color: $c-white;
  &.-yellow {
    background-color: $c-yellow;
  }
  &.-red {
    background-color: $c-red;
  }
  &.-green {
    background-color: $c-green-light;
  }
}

@mixin infoIcons() {
  &.-calendar {
    &:before {
      background: url(../img/icons/calendar.svg);
    }
  }
  &.-location {
    &:before {
      background: url(../img/icons/location_blue.svg);
    }
  }
  &.-numbers {
    &:before {
      background: url(../img/icons/profile_blue.svg);
    }
  }
  &.-status {
    &:before {
      background: url(../img/icons/info_blue.svg);
    }
  }
  &.-money {
    &:before {
      background: url(../img/icons/money.svg);
    }
  }
  &.-access {
    &:before {
      background: url(../img/icons/access.svg);
    }
  }
  &.-building {
    &:before {
      background: url(../img/icons/building.svg);
    }
  }
  &.-contents {
    &:before {
      background: url(../img/icons/contents.svg);
    }
  }
}


// SVG画像を内部処理
 @function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}
@function url-encode($string) {
  $map: (
    "%": "%25",
    "<": "%3C",
    ">": "%3E",
    " ": "%20",
    "!": "%21",
    "*": "%2A",
    "'": "%27",
    '"': "%22",
    "(": "%28",
    ")": "%29",
    ";": "%3B",
    ":": "%3A",
    "@": "%40",
    "&": "%26",
    "=": "%3D",
    "+": "%2B",
    "$": "%24",
    ",": "%2C",
    "/": "%2F",
    "?": "%3F",
    "#": "%23",
    "[": "%5B",
    "]": "%5D"
  );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }
  @return $new;
}

// SVG呼び出し
@function inline-svg($string) {
  @return url('data:image/svg+xml;utf8,#{url-encode($string)}');
}